import clsx from 'clsx'
import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination, useSortBy} from 'react-table'
import {toAbsoluteUrl} from '../../../../../../../_ePulse/helpers'
import {useQuery} from '@apollo/client'
import {CellData} from './columns/CellData'
import {Popup} from '../components/Modal'

import {CommonHeader} from './columns/CommonHeader'

import {toast} from 'react-toastify'
import {ToastMessageContainer} from '../../../../../../../_ePulse/layout/components/ToastMessageContainer'
import { GET_PATIENT_LIST } from '../../../../../PharmaModule/GraphQLQueries/querys'
import { G } from '@react-pdf/renderer'
import { AddPatientModal } from './modals/AddPatient'
import { GET_PATIENT_LIST_PAGINATION } from '../../../../../ScanCenterModule/GraphQLQueries/Patients'
import { ActionsCell } from './ActionCell'

export const PatientListTable = () => {
  const [loadingLoader, setLoadings] = useState(false)
 
  const Columns = [
    {
      Header: (props: any) => (
        <CommonHeader
          tableProps={props}
          title='First Name'
          className='d-flex ms-5 text-black  '
        />
      ),
      accessor: 'Speciality',
      id: 'Speciality',
      Cell: (props: any) => <CellData tableProps={props.data[props.row.index]?.firstName} />,
    },
    {
      Header: (props: any) => (
        <CommonHeader
          tableProps={props}
          title='Last Name'
          className='d-flex ms-5 text-black '
        />
      ),
      id: 'name',
      accessor: 'name',
      Cell: (props: any) => <CellData tableProps={props.data[props.row.index]?.lastName} />,
    },
    {
      Header: (props: any) => (
        <CommonHeader
          tableProps={props}
          title='Contact Number'
          className='d-flex ms-5 text-black '
        />
      ),
      id: 'code',
      accessor: 'code',
      Cell: (props: any) => <CellData tableProps={props.data[props.row.index]?.mobileNumber} />,
    },
    {
      Header: (props: any) => (
        <CommonHeader
          tableProps={props}
          title='Gender'
          className='d-flex ms-5 text-black '
        />
      ),
      id: 'gender',
      accessor: 'gender',
      Cell: (props: any) => <CellData tableProps={props.data[props.row.index]?.gender} />,
    },
    {
      Header: (props: any) => (
        <CommonHeader tableProps={props} title='Actions' className='d-flex ms-5 text-black' />
      ),
      id: 'Actions',
      Cell: (props: any) => (
        <ActionsCell tableProps={props.data[props.row.index]} refetch={refetch} />
      ),
    },
  ]

  const [listing, setList] = useState([])
  const [pageNumber, setpageNumber] = useState(1)
  const [limit, setlimit] = useState(10)
  const [totalPages, settotalPages] = useState(1)
  const [modalState, setModalState] = useState(false)
  const [modalStates, setModalStates] = useState(false)
  const {data: datalist, refetch} = useQuery(GET_PATIENT_LIST_PAGINATION, {
    variables: {
      page: pageNumber,
      limit: limit,
      specialityId: undefined,
      countryCode: undefined,
      isActive: undefined,
    },
    // onError: (error: any) => {
    //   if (error?.networkError?.statusCode === 440) {
    //     toast.error(`${error?.networkError?.result?.errors[0]?.message}`)
    //   } else {
    //     toast.error(error?.message)
    //   }
    // },
    // onCompleted: (data: any) => {
    //   console.log('data======>',data)
    //   setList(data?.getBusinessPatientPaginationList?.data)
    //   settotalPages(data?.getBusinessPatientPaginationList?.totalPages)
    //   setpageNumber(data?.getBusinessPatientPaginationList?.page)
    // },
  })
  useEffect(() => {
    setLoadings(true)
    if (datalist) {
      setLoadings(false)
      setList(datalist?.getBusinessPatientPaginationList?.data)
      settotalPages(datalist?.getBusinessPatientPaginationList?.totalPages)
      setpageNumber(datalist?.getBusinessPatientPaginationList?.page)
    }
    else{
      setLoadings(false)
    }
  }, [datalist, listing])
  const data = listing
  const columns: any = useMemo(() => Columns, [])

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  )
  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    prepareRow,
    state: {pageSize},
    setPageSize,
  }: any = tableInstance

  const closeModal = () => {
    setModalStates(false)
    // props.refetch()
  }
  return (
    <div>
      <ToastMessageContainer />
          {loadingLoader && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
      {modalStates && <AddPatientModal modalState={modalStates} refetch={refetch} closeModal={() => closeModal()} />}
    
      <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
        <span className='page-header'>Patient</span>
        <div className='d-flex flex-row  align-items-center mb-3'>
        <div className='filter-row ePulse-divider justify-content-end'>
        <button
          className='ePulse-button'
          onClick={() => {
            setModalState(true)
          }}
        >
          Upload Patient
        </button>
      </div>
      <div className='filter-row ePulse-divider justify-content-end'>
      <button className={clsx('ePulse-button')} onClick={() => setModalStates(true)}>
            <span className='fs-6 text-white'>Add Patient</span>
          </button>
          </div>
        </div>
      </div>
      <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
    
      </div>
      <Popup modalState={modalState} setModalState={setModalState} refetch={refetch}/>
    
      <div className='admin-table'>
        <table {...getTableProps()} className='table '>
          <thead>
            <tr className='text-center text-dark fw-bolder fs-6 MyReactTableClass'>
              {headers.map((column: any) => {
                return (
                  <th
                    className={
                      column?.isSorted === false && column?.canSort
                        ? 'sortable'
                        : column?.isSorted === true && column?.isSortedDesc === false
                        ? 'sortable asc'
                        : column?.isSorted === true && column?.isSortedDesc === true
                        ? 'sortable desc'
                        : ''
                    }
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                  </th>
                )
              })}
            </tr>
          </thead>

          <tbody className='text-black fw-normal text-center' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: any, index: number) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} className={index === 0 ? 'bg-white' : 'bg-white'}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={clsx({
                            'text-end min-w-100px p-1': cell.column.id === 'actions',
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <Pagination /> */}

        {listing.length > 0 && (
          <div className='pagination'>
            <div className='page-show'>
              <span>Show Enteries</span>
              <select
                className='ms-2 ePulse-pagination-dropdown'
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value))
                  setlimit(Number(e.target.value))
                  setpageNumber(1)
                  refetch({limit: parseInt(e.target.value)})
                }}
              >
                {[10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
            <div className='page-num'>
              <button
                className={
                  pageNumber === 1
                    ? 'ePulse-pagination-button'
                    : clsx('ePulse-pagination-button text-white')
                }
                onClick={() => {
                  setpageNumber(pageNumber - 1)
                  refetch({page: pageNumber - 1, limit: 10})
                }}
                disabled={pageNumber === 1}
              >
                {/* Previous */}
                <img alt='Previous' src={toAbsoluteUrl('/media/icons/duotune/arrows/arr022.svg')} />
              </button>
              <span className='mx-3'>
                Page{' '}
                <strong>
                  {pageNumber} of {totalPages}
                </strong>{' '}
              </span>
              <button
                className={
                  pageNumber === totalPages
                    ? 'ePulse-pagination-button'
                    : clsx('ePulse-pagination-button text-white')
                }
                onClick={() => {
                  setpageNumber(pageNumber + 1)
                  refetch({page: pageNumber + 1, limit: 10})
                }}
                disabled={pageNumber === totalPages}
              >
                {/* Next */}
                <img alt='Next' src={toAbsoluteUrl('/media/icons/duotune/arrows/arr023.svg')} />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
