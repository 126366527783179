import {gql} from '@apollo/client'

export const GET_INVENTORY_LIST = gql`
  query getInventoryList($page: Int!, $limit: Int!) {
    getInventoryList(listFilter: {page: $page, limit: $limit}) {
      data {
        id
        productId
        productName
        mrpPerUnit
        batchNumber
        mrp
        expriryDate
        remainingQuantity
        quantity
        __typename
      }
      total
      pageSize
      page
      totalPages
      __typename
    }
  }
`

export const GET_VENDOR_LIST = gql`
  query getVendorList($page: Int!, $limit: Int!) {
    getVendorList(listFilter: {page: $page, limit: $limit}) {
      data {
        id
        mobileNumber
        name
        firmName
        __typename
        email
        zip
      }
      total
      pageSize
      page
      totalPages
      __typename
    }
  }
`

export const GET_PATIENT_LIST = gql`
  query getBusinessPatientPaginationList($page: Int!, $limit: Int!) {
    getBusinessPatientPaginationList(listFilter: {page: $page, limit: $limit}) {
      data {
        id
        mobileNumber
        name
        profilePhoto
        __typename
      }
      total
      pageSize
      page
      totalPages
      __typename
    }
  }
`

export const GET_SAMPLE_PATIENT_FILE = gql`
  query getPatientSampleFile {
    getPatientSampleFile
  }
`
export const GET_SAMPLE_VENDOR_FILE = gql`
  query GetVendorSampleFile {
    getVendorSampleFile
  }
`
export const GET_SAMPLE_INVENTORY_FILE = gql`
  query getInventorySampleFile {
    getInventorySampleFile
  }
`

export const GET_DASHBOARD_COUNT_TOP = gql`
  query getDashboardCounts {
    getDashboardCounts {
      medicineInStock
      expirySoon
      orderRestock
      registeredUser
      registeredVendor
      totalMonthlyOrder
      registeredDoctor
      doctorSaleOrder
    }
  }
`

export const GET_DASHBOARD_REPORT_COUNT = gql`
  query getDashboardReportCount($month: Int!, $year: Int!) {
    getDashboardReportCount(month: $month, year: $year) {
      quantityOFSoldMedicine
      invoiceGenerated
      revenue
      profit
    }
  }
`

export const GET_REPORT_COUNT = gql`
  query getMonthlyReportCount($month: Int!, $year: Int!) {
    getMonthlyReportCount(month: $month, year: $year) {
      totalSalesOrder
      totalVendors
      totalPurchaseOrder
      totalPatients
    }
  }
`

export const GET_SCAN_DASHBOARD_REPORT_COUNT = gql`
  query {
    getDiagnosisReportCount {
      registeredUser
      registeredDoctor
      todayActiveAppointment
      waitingForResult
      reportReady
      closedReport
      consumerAppointment
      doctorAppointment
    }
  }
`

export const GET_SCAN_REPORT_COUNT = gql`
  query getDiagnosisMonthlyCount($month: Int!, $year: Int!) {
    getDiagnosisMonthlyCount(month: $month, year: $year) {
      monthlyTransaction
      monthlyAppointment
      monthlyRevenue
    }
  }
`

export const GET_MATCHED_PRODUCT_LIST = gql`
  query getMatchedProductList(
    $productName: String!
    $isBusinessAdmin: Boolean
    $page: Int!
    $limit: Int!
  ) {
    getMatchedProductList(
      productName: $productName
      isBusinessAdmin: $isBusinessAdmin
      listFilter: {page: $page, limit: $limit}
    )
  }
`
