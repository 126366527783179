import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_ePulse/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'

import {PharmaDashboardWrapper} from '../MasterModules/DoctorModule/pages/dashboard/PharmaDashboardWrapper'
import {ScanCenterDashboardWrapper} from '../MasterModules/DoctorModule/pages/dashboard/ScanCenterDashboardWrapper'
import {MenuTestPage} from '../MasterModules/DoctorModule/pages/MenuTestPage'
import {getCSSVariableValue} from '../../_ePulse/assets/ts/_utils'
import {WithChildren} from '../../_ePulse/helpers'
import BuilderPageWrapper from '../MasterModules/DoctorModule/pages/layout-builder/BuilderPageWrapper'
import {DashboardWrapper} from '../MasterModules/DoctorModule/pages/dashboard/DashboardWrapper'
import PatientUpload from '../MasterModules/ScanCenterModule/pages/PatientUpload'
import ProfilePages from '../MasterModules/ScanCenterModule/patient/patientPage'
import PDF from '../MasterModules/ScanCenterModule/PDF'

const ScanRoutes = () => {
  const ProfilePage = lazy(() => import('../common/modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../common/modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../common/modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../common/modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../common/modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../common/modules/apps/user-management/UsersPage'))
  const SwitchBusinessPage = lazy(
    () => import('../common/modules/switchBusiness/SwitchBusinessPage')
  )
  const AddAppointmentPage = lazy(
    () => import('../MasterModules/DoctorModule/modules/addAppointments/AddAppointmentPage')
  )
  const AppointmentPage = lazy(
    () => import('../MasterModules/DoctorModule/modules/appointment/AppointmentPage')
  )
  const AddPatientsPage = lazy(
    () => import('../MasterModules/DoctorModule/modules/addPatients/ProfilePage')
  )
  const ConsultingFeesPage = lazy(
    () => import('../MasterModules/DoctorModule/modules/consultingFess/FeesPage')
  )
  const ConsultingTimingPage = lazy(
    () => import('../MasterModules/DoctorModule/modules/consultingTiming/TimingPage')
  )
  const CalendarPage = lazy(
    () => import('../MasterModules/DoctorModule/modules/calendar/CalendarPage')
  )
  // const SettingsPage = lazy(() => import('../MasterModules/DoctorModule/modules/settings/SettingsPage'))
  const SettingsPage = lazy(
    () => import('../MasterModules/DoctorModule/modules/settingsMain/appSettings/AppSettingsPage')
  )

  const ReportsPage = lazy(() => import('../MasterModules/DoctorModule/modules/reports/ReportPage'))
  const StaffPage = lazy(
    () => import('../MasterModules/DoctorModule/modules/staff/StaffManagmentPage')
  )
  const RxTemplate = lazy(
    () => import('../MasterModules/DoctorModule/modules/RXModule/template/RXPage')
  )
  const RxPrescription = lazy(
    () => import('../MasterModules/DoctorModule/modules/RXModule/prescription/PrescriptionPage')
  )
  const PaymentPage = lazy(
    () => import('../MasterModules/ScanCenterModule/modules/transaction/payment/PaymentPage')
  )

  const SettlementsPage = lazy(
    () => import('../MasterModules/ScanCenterModule/modules/transaction/settlements/SettlementsPage')
  )

  const SummaryPage = lazy(() => import('../MasterModules/ScanCenterModule/modules/transaction/summary/SummaryPage'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path='auth/*' element={<Navigate to='/dashboard' />} /> */}
        {/* Pages */}

        <Route path='dashboard' element={<ScanCenterDashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='patientupload' element={<ProfilePages />} />
        <Route path='booking_slot_scan' element={<PDF />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        {/* NEW ROUTES */}

        <Route
          path='calendar/*'
          element={
            <SuspensedView>
              <CalendarPage />
            </SuspensedView>
          }
        />

        <Route
          path='add_appointment/*'
          element={
            <SuspensedView>
              <AddAppointmentPage />
            </SuspensedView>
          }
        />

        <Route
          path='appointment/*'
          element={
            <SuspensedView>
              <AppointmentPage />
            </SuspensedView>
          }
        />

        <Route
          path='add_patient/*'
          element={
            <SuspensedView>
              <AddPatientsPage />
            </SuspensedView>
          }
        />

        <Route
          path='consulting_fees/*'
          element={
            <SuspensedView>
              <ConsultingFeesPage />
            </SuspensedView>
          }
        />

        <Route
          path='consulting_timing/*'
          element={
            <SuspensedView>
              <ConsultingTimingPage />
            </SuspensedView>
          }
        />

        {/* <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='application_setting/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />

        <Route
          path='reports/*'
          element={
            <SuspensedView>
              <ReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path='staff-managment/*'
          element={
            <SuspensedView>
              <StaffPage />
            </SuspensedView>
          }
        />

        <Route
          path='rx_template/*'
          element={
            <SuspensedView>
              <RxTemplate />
            </SuspensedView>
          }
        />

        <Route
          path='rx_prescription/*'
          element={
            <SuspensedView>
              <RxPrescription />
            </SuspensedView>
          }
        />

        <Route
          path='switch_business/*'
          element={
            <SuspensedView>
              <SwitchBusinessPage />
            </SuspensedView>
          }
        />
     <Route
          path='summary/*'
          element={
            <SuspensedView>
              <SummaryPage />
            </SuspensedView>
          }
        />

        <Route
          path='payments/*'
          element={
            <SuspensedView>
              <PaymentPage />
            </SuspensedView>
          }
        />

        <Route
          path='settlements/*'
          element={
            <SuspensedView>
              <SettlementsPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {ScanRoutes}
