/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Modal, Row} from 'react-bootstrap'
import {useMutation, useQuery} from '@apollo/client'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import '../../../../../../App.css'
import {toAbsoluteUrl} from '../../../../../../_ePulse/helpers'
import {
  GET_DOCTOR_SPECIALITY_LIST,
  GET_STAFF_ROLL,
  GET_STATE_COUNCIL_LIST,
} from '../../../GraphQLQueries/Master'
import {CREATE_STAFF_MEMBER} from '../../../GraphQLQueries/StaffManagment'

interface StaffMemberDetails {
  firstName: string
  lastName: string
  mobileNumber: number
  isActive: boolean
  email: string
  staffRoleId: string
  gender: string
  mciNumber: string
  stateCouncil: string
  speciality: string
}

const initialValues: StaffMemberDetails = {
  firstName: '',
  lastName: '',
  mobileNumber: 0,
  isActive: true,
  email: '',
  staffRoleId: 'default',
  gender: 'default',
  mciNumber: '',
  stateCouncil: 'default',
  speciality: 'default',
}

const addStaffSchema = Yup.object().shape({
  mobileNumber: Yup.number()
    .required('Mobile Number is required')
    .max(9999999999, 'Enter Valid Number')
    .min(1000000000, 'Enter Valid Number'),
  email: Yup.string().email().required('Email is required'),
  gender: Yup.string()
    .required(' Gender is required')
    .not(['default'])
    .oneOf(['Male', 'Female', 'Other'], 'Select Gender'),
  staffRoleId: Yup.string().required('Select Staff Roll').not(['default'], 'Select Staff Roll'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
})

export function AddStaffModal(props: any) {
  const [roles, setRoles] = useState([])
  const [speciality, setspeciality] = useState([])
  const [stateCouncilList, setstateCouncilList] = useState([])
  const [error, setError] = useState<Boolean>(false)
  const [errorMsg, setErrorMsg] = useState<any>()
  const [checked, setChecked] = React.useState(false)
  const {
    loading: loading1,
    error: error1,
    refetch,
  } = useQuery(GET_STAFF_ROLL, {
    onError: (error: any) => {
      if (error.message === 'No data found.') {
        setRoles([])
      }
    },
    onCompleted: (data: any) => {
      const res = data?.getStaffRoleList
      setRoles(res)
    },
  })

  const {
    loading: loading3,
    error: error3,
    refetch: refetch3,
  } = useQuery(GET_DOCTOR_SPECIALITY_LIST, {
    onError: (error: any) => {
      if (error.message === 'No data found.') {
        setspeciality([])
      }
    },
    onCompleted: (data: any) => {
      const res = data?.getDoctorSpecialityList?.data
      const filterRes = res.filter((item: any) => item.isActive === true)
      setspeciality(filterRes)
    },
  })

  const {
    loading: loading4,
    error: error4,
    refetch: refetch4,
  } = useQuery(GET_STATE_COUNCIL_LIST, {
    onError: (error: any) => {
      if (error.message === 'No data found.') {
        setspeciality([])
      }
    },
    onCompleted: (data: any) => {
      const res = data?.getStateCouncilList?.data
      const filterRes = res.filter((item: any) => item.isActive === true)
      setstateCouncilList(filterRes)
    },
  })

  const [addStaff, {data, error: error2}] = useMutation(CREATE_STAFF_MEMBER, {
    onError: (error: any) => {
      setError(true)
      setErrorMsg(error.message)
    },

    onCompleted: (data: any) => {
      props.setModalState(false)
      props.refetch()
    },
  })
  useEffect(() => {
    // if (data) {
    //   console.log('DATAADDADMIN.......', data)
    // }
  }, [data])

  const handleChange = () => {
    setChecked(!checked)
    formik.setFieldValue('isActive', !checked)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: addStaffSchema,
    validate: (values) => {
      var errors: any = {}
      if (values.staffRoleId === '6418329179e9b117057e1bda') {
        if (values.mciNumber === '') {
          errors.mciNumber = 'Enter MCI Number'
        }
        if (values.stateCouncil === 'default') {
          errors.stateCouncil = 'Select State Council'
        }
        if (values.speciality === 'default') {
          errors.speciality = 'Select Speciality'
        }
      }
      return errors
    },
    onSubmit: async (values) => {
      try {
        await addStaff({
          variables: {
            firstName: values.firstName,
            lastName: values.lastName,
            mobileNumber: values.mobileNumber,
            isActive: values.isActive,
            email: values.email,
            staffRoleId: values.staffRoleId,
            gender: values.gender,
            mciNumber: values.mciNumber === '' ? undefined : values.mciNumber,
            stateCouncil: values.stateCouncil === '' ? undefined : values.stateCouncil,
            speciality: values.speciality === '' ? undefined : values.speciality,
          },
        })
      } catch (error) {
        // setLoading(false)
      }
    },
  })

  console.log('.....', formik)

  return (
    <>
      <div>
        <Modal
          show={props.modalState}
          dialogClassName='modal-90w'
          onHide={() => props.setModalState(false)}
          // backdrop='static'
        >
          <Modal.Header closeButton className='h-50px'>
            <span className='text-black fs-1 fw-bold '>Add Staff Member</span>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
              <div className='d-flex flex-row'>
                <div className='me-4 col-sm-3'>
                  <div className='position-relative'>
                    <img
                      src={toAbsoluteUrl('/media/ePulse/userIcon.svg')}
                      width={100}
                      height={100}
                    />
                    <img
                      src={toAbsoluteUrl('/media/ePulse/camera.svg')}
                      className='position-absolute bottom-0 end-0'
                    />
                  </div>
                </div>
                <div className='d-flex flex-column w-100'>
                  {/* <div className=' border-bottom border-grey mb-4'>
                  <span className='text-black fs-1 fw-bold '>Add Staff Member</span>
                </div> */}
                  <div className='d-flex flex-column mb-3'>
                    <TextField
                      className=''
                      id='outlined-basic'
                      label='Staff First Name.*'
                      placeholder='Staff First Name.*'
                      variant='outlined'
                      type={'text'}
                      size='small'
                      fullWidth
                      {...formik.getFieldProps('firstName')}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger'>{formik.errors.firstName}</span>
                      </div>
                    )}
                  </div>
                  <div className='d-flex flex-column mb-3'>
                    <TextField
                      className=''
                      id='outlined-basic'
                      label='Staff Last Name*'
                      placeholder='Staff Last Name*'
                      variant='outlined'
                      type='text'
                      size='small'
                      fullWidth
                      {...formik.getFieldProps('lastName')}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger'>{formik.errors.lastName}</span>
                      </div>
                    )}
                  </div>
                  <div className='d-flex flex-column mb-3'>
                    <TextField
                      className=''
                      id='outlined-basic'
                      label='Staff Mobile*'
                      placeholder='Staff Mobile*'
                      variant='outlined'
                      type='number'
                      size='small'
                      fullWidth
                      inputProps={{inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10}}
                      {...formik.getFieldProps('mobileNumber')}
                    />
                    {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger'>{formik.errors.mobileNumber}</span>
                      </div>
                    )}
                  </div>
                  <div className='d-flex flex-column mb-3'>
                    <TextField
                      className=''
                      id='outlined-basic'
                      label='Staff Email ID*'
                      placeholder='Staff Email ID*'
                      variant='outlined'
                      type='text'
                      size='small'
                      fullWidth
                      {...formik.getFieldProps('email')}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger'>{formik.errors.email}</span>
                      </div>
                    )}
                  </div>
                  <div className='d-flex flex-column mb-3'>
                    <Box className=''>
                      <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>Gender</InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          size='small'
                          label='Gender'
                          {...formik.getFieldProps('gender')}
                        >
                          <MenuItem value={'default'}>Select Gender</MenuItem>
                          <MenuItem value={'Male'}>Male</MenuItem>
                          <MenuItem value={'Female'}>Female</MenuItem>
                          <MenuItem value={'Other'}>Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    {formik.touched.gender && formik.errors.gender && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger'>{formik.errors.gender}</span>
                      </div>
                    )}
                  </div>
                  <div className='d-flex flex-column mb-3'>
                    <Box className=''>
                      <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>Staff Role</InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          size='small'
                          label='Staff Role'
                          {...formik.getFieldProps('staffRoleId')}
                        >
                          <MenuItem key={'default'} value={'default'}>
                            Select Staff Role
                          </MenuItem>

                          {roles?.map((role: any) => {
                            return (
                              <MenuItem value={role.id} key={role.id}>
                                {role.name}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    {formik.touched.staffRoleId && formik.errors.staffRoleId && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger'>{formik.errors.staffRoleId}</span>
                      </div>
                    )}
                  </div>
                  {/* <div className='d-flex flex-row mb-3'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Status</label>

                    <div className='col-lg-8 d-flex align-items-center'>
                      <div className='form-check form-check-solid form-switch fv-row'>
                        <input
                          className='form-check-input w-45px h-30px checkbutton'
                          type='checkbox'
                          checked={checked}
                          onChange={() => {
                            handleChange()
                          }}
                        />
                        <label className='form-check-label'></label>
                      </div>
                    </div>
                  </div> */}
                  {formik.values.staffRoleId === '642febfe41dbd28fb51b6677' ? (
                    <>
                      <div className='d-flex flex-column mb-3'>
                        <TextField
                          className=''
                          id='outlined-basic'
                          label='MCI Number'
                          placeholder='MCI Number'
                          variant='outlined'
                          type='text'
                          size='small'
                          fullWidth
                          {...formik.getFieldProps('mciNumber')}
                        />
                        {formik.touched.mciNumber && formik.errors.mciNumber && (
                          <div className='fv-plugins-message-container'>
                            <span className='text-danger'>{formik.errors.mciNumber}</span>
                          </div>
                        )}
                      </div>

                      {/* <div className='d-flex flex-column mb-3'>
                        <TextField
                          className=''
                          id='outlined-basic'
                          label='State Council'
                          placeholder='State Council'
                          variant='outlined'
                          type='text'
                          size='small'
                          fullWidth
                          {...formik.getFieldProps('stateCouncil')}
                        />
                        {formik.touched.stateCouncil && formik.errors.stateCouncil && (
                          <div className='fv-plugins-message-container'>
                            <span className='text-danger'>{formik.errors.stateCouncil}</span>
                          </div>
                        )}
                      </div> */}

                      <div className='d-flex flex-column mb-3'>
                        <Box className=''>
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>State Council</InputLabel>
                            <Select
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              size='small'
                              label='State Council'
                              {...formik.getFieldProps('stateCouncil')}
                            >
                              <MenuItem key={'default'} value={'default'}>
                                Select State Council
                              </MenuItem>

                              {stateCouncilList?.map((role: any) => {
                                return (
                                  <MenuItem value={role.id} key={role.id}>
                                    {role.name}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                        {formik.touched.stateCouncil && formik.errors.stateCouncil && (
                          <div className='fv-plugins-message-container'>
                            <span className='text-danger'>{formik.errors.stateCouncil}</span>
                          </div>
                        )}
                      </div>

                      <div className='d-flex flex-column mb-3'>
                        <Box className=''>
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>Speciality</InputLabel>
                            <Select
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              size='small'
                              label='Speciality'
                              {...formik.getFieldProps('speciality')}
                            >
                              <MenuItem key={'default'} value={'default'}>
                                Select Speciality
                              </MenuItem>

                              {speciality?.map((role: any) => {
                                return (
                                  <MenuItem value={role.id} key={role.id}>
                                    {role.name}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                        {formik.touched.speciality && formik.errors.speciality && (
                          <div className='fv-plugins-message-container'>
                            <span className='text-danger'>{formik.errors.speciality}</span>
                          </div>
                        )}
                      </div>

                      {/* <Box className='my-4'>
                        <FormControl fullWidth>
                          <InputLabel id='demo-simple-select-label'>State Council</InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            size='small'
                            value={stateCouncil}
                            label='State Council'
                            onChange={handleChangeStateCouncil}
                          >
                            <MenuItem value={10}>Male</MenuItem>
                            <MenuItem value={20}>Female</MenuItem>
                            <MenuItem value={30}>Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>

                      <Box className='my-4'>
                        <FormControl fullWidth>
                          <InputLabel id='demo-simple-select-label'>Speciality</InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            size='small'
                            value={speciality}
                            label='Speciality'
                            onChange={handleChangeSpeciality}
                          >
                            <MenuItem value={10}>Doctor</MenuItem>
                            <MenuItem value={20}>Nurse</MenuItem>
                            <MenuItem value={30}>Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Box> */}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {error && error && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{errorMsg}</span>
                </div>
              )}
              <div className='d-flex flex-row justify-content-center mt-6 '>
                <button
                  className='border-0 ePulse-appColor-primary px-6 py-3 fs-6 rounded-pill text-white '
                  type='submit'
                >
                  <span className='fw-semibold'>Confirm & Add Staff</span>
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}
