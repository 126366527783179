/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import useLocalStorageFetch from '../../../../hooks/localStorageFetch'
import {useSelector} from 'react-redux'
import {checkPermission} from '../../../../app/App'

export function AsideMenuMain() {
  const intl = useIntl()
  const dataUser: any = useSelector((state) => state)
  const {role} = useLocalStorageFetch()
  return (
    <>
      <AsideMenuItem
        to={'/dashboard'}
        title='Dashboard'
        icon='/media/ePulse/icons/aside/dashboard.svg'
        heading='Home'
        className='py-3'
      ></AsideMenuItem>
      {dataUser?.auth?.role === 'Doctor' && (
        <>
          {(checkPermission('getClinicRxTemplateList') ||
            checkPermission('getPaginationRxPrescriptionTemplateList') ||
            checkPermission('getAppointmentPaginationList')) && (
            <AsideMenuItemWithSubMain
              to='/rxmodule'
              title='Create Rx'
              icon='/media/ePulse/icons/aside/prescription.svg'
              heading='RX'
            >
              {checkPermission('getClinicRxTemplateList') && (
                <AsideMenuItem to={`/${role}/rx_template`} title='Template' />
              )}
              {checkPermission('getPaginationRxPrescriptionTemplateList') && (
                <AsideMenuItem to={`/${role}/prescription-codes`} title='Prescription Codes' />
              )}
              {checkPermission('getAppointmentPaginationList') && (
                <AsideMenuItem to={`/${role}/prescription-history`} title='Prescription History' />
              )}
              {/* {checkPermission('getDoctorCustomMedicineList') && */}
              <AsideMenuItem to={`/${role}/custom-medicines`} title='Custom Medicines' />
              {/* } */}
            </AsideMenuItemWithSubMain>
          )}
        </>
      )}

      {dataUser?.auth?.role === 'Doctor' && (
        <>
          {(checkPermission('getDoctorPrescription') ||
            checkPermission('getBusinessStaffList')) && (
            <AsideMenuItemWithSubMain
              to='/settingssection'
              title='Setting'
              icon='/media/ePulse/icons/aside/setting.svg'
              heading='Setting'
            >
              {checkPermission('getDoctorPrescription') && (
                <AsideMenuItem
                  // icon='/media/ePulse/icons/aside/setting.svg'
                  to={`/${role}/application_setting/prescription_designer`}
                  title='Prescription Designer'
                  // heading='Setting'
                />
              )}
              {checkPermission('getBusinessStaffList') && (
                <AsideMenuItem
                  to={`/${role}/appointment-duration`}
                  title='Appointment Duration'
                ></AsideMenuItem>
              )}
            </AsideMenuItemWithSubMain>
          )}
        </>
      )}
      {dataUser?.auth?.role === 'Doctor' && (
        <>
          {checkPermission('getBusinessPatientPaginationList') && (
            <AsideMenuItem
              to={`/${role}/patients`}
              title='Patients'
              icon='/media/ePulse/icons/aside/vendor.png'
              heading='Patients'
              className='py-3'
            ></AsideMenuItem>
          )}
          {checkPermission('getRefferedUserList') && (
            <AsideMenuItemWithSubMain
              to={`/${role}/refer`}
              title='Doctor'
              icon='/media/ePulse/icons/aside/refer.png'
              heading='Refer'
            >
              <AsideMenuItem to={`/${role}/refer`} title='Refer'></AsideMenuItem>
            </AsideMenuItemWithSubMain>
          )}
        </>
      )}
      {dataUser?.auth?.role === 'ScanCenter' && (
        <>
          <AsideMenuItemWithSubMain
            to='/settingssection'
            title='Settings'
            icon='/media/ePulse/icons/aside/setting.svg'
            heading='Settings'
          >
            <AsideMenuItem to={`/${role}/booking_slot_scan`} title='Booking Slot' />
          </AsideMenuItemWithSubMain>
        </>
      )}
      {dataUser?.auth?.role === 'Pharmacy' && (
        <>
          <AsideMenuItem
            to={`/${role}/inventory`}
            title='inventory'
            icon='/media/ePulse/icons/aside/inventory.png'
            heading='Inventory'
            className='py-3'
          ></AsideMenuItem>
          <AsideMenuItem
            to={`/${role}/vendor`}
            title='vendor'
            icon='/media/ePulse/icons/aside/vendor.png'
            heading='Vendor'
            className='py-3'
          ></AsideMenuItem>
          <AsideMenuItem
            to={`/${role}/patient`}
            title='patients'
            icon='/media/ePulse/icons/aside/patient.png'
            heading='Patients'
            className='py-3'
          ></AsideMenuItem>
          <AsideMenuItemWithSubMain
            to='/settingssection'
            title='Setting'
            icon='/media/ePulse/icons/aside/setting.svg'
            heading='Setting'
          >
            {true && (
              <AsideMenuItem
                // icon='/media/ePulse/icons/aside/setting.svg'
                to={`/${role}/prescription-codes`}
                title='Prescription Code'
                // heading='Setting'
              />
            )}
          </AsideMenuItemWithSubMain>
        </>
      )}
      {dataUser?.auth?.role === 'ScanCenter' && (
        <>
          <AsideMenuItem
            to={`/${role}/patientupload`}
            title='inventory'
            icon='/media/ePulse/icons/aside/patient.png'
            heading='Patients'
            className='py-3'
          ></AsideMenuItem>
          <AsideMenuItemWithSubMain
            to='/summary'
            title='Summary'
            fontIcon='bi-sticky'
            icon='/media/ePulse/icons/aside/inventory.png'
            heading='Summary'
          >
            {checkPermission('getTotalTransactionGraphHistory') &&
              checkPermission('getTotalTransactionHistory') && (
                <AsideMenuItem to={`/${role}/summary`} title='Summary' />
              )}
            {checkPermission('getOrderPaymentHistoryListForBusinessAdmin') && (
              <AsideMenuItem to={`/${role}/payments`} title='Payments' />
            )}
            {checkPermission('getBusinessAdminSettlementList') && (
              <AsideMenuItem to={`/${role}/settlements`} title='Settlements' />
            )}
          </AsideMenuItemWithSubMain>
        </>
      )}

      {/* <AsideMenuItem
        to='/reports'
        title='Reports'
        icon='/media/ePulse/icons/aside/reports.svg'
        heading='Reports'
        className='py-3'
      ></AsideMenuItem> */}
      {dataUser?.auth?.role === 'Doctor' && (
        <>
          {((checkPermission('getTotalTransactionGraphHistory') &&
            checkPermission('getTotalTransactionHistory')) ||
            checkPermission('getOrderPaymentHistoryListForClinic') ||
            checkPermission('getBusinessAdminSettlementList')) && (
            <AsideMenuItemWithSubMain
              to='/summary'
              title='Summary'
              fontIcon='bi-sticky'
              icon='/media/ePulse/icons/aside/inventory.png'
              heading='Summary'
            >
              {checkPermission('getTotalTransactionGraphHistory') &&
                checkPermission('getTotalTransactionHistory') && (
                  <AsideMenuItem to={`/${role}/summary`} title='Summary' />
                )}
              {checkPermission('getOrderPaymentHistoryListForClinic') && (
                <AsideMenuItem to={`/${role}/payments`} title='Payments' />
              )}
              {checkPermission('getBusinessAdminSettlementList') && (
                <AsideMenuItem to={`/${role}/settlements`} title='Settlements' />
              )}
            </AsideMenuItemWithSubMain>
          )}
        </>
      )}
    </>
  )
}
