import React from 'react'
import './Card.css' // Import the CSS file for styles

const Card = ({heading = '', count = 0, isScan=false}) => {
  return (
    <div className={isScan ? 'scanBox':'box'}>
      <p className="top-head">{heading}</p>
      <div>
        <p className='cont-text'>
          {' '}
          {heading === 'Revenue' || heading === 'Profit' ? '₹' : ''} {count}
        </p>
      </div>
    </div>
  )
}

export default Card
