import {gql} from '@apollo/client'

export const GET_MY_BUSINESS_LIST = gql`
  query getMyBusinessList($page: Int!, $limit: Int!, $businessType: [BUSINESS_TYPES]) {
    getMyBusinessList(
      myBusiness: {listFilter: {page: $page, limit: $limit}, businessType: $businessType}
    ) {
      data {
        id
        businessType
        associatedName
        isDefaultBusiness
        isApproved
        isBusinessSetupCompleted
        __typename
      }
      total
      pageSize
      page
      totalPages
      __typename
    }
  }
`

export const SET_DEFAULT_BUSINESS_ADD = gql`
  mutation setDefaultBusinessActive($id: ID!) {
    setDefaultBusinessActive(id: $id) {
      id
    }
  }
`
export const SWITCH_USER_ACCOUNT = gql`
  mutation switchUserAccount(
    $role: String
    $roleId: String
    $deviceType: deviceType!
    $ipAddress: String
    $userAgent: String
    $browserUniqueId: String
    $browserName: String
  ) {
    switchUserAccount(
      switchUsrInput: {
        role: $role
        roleId: $roleId
        deviceDetail: {
          deviceType: $deviceType
          ipAddress: $ipAddress
          userAgent: $userAgent
          browserUniqueId: $browserUniqueId
          browserName: $browserName
        }
      }
    ) {
      userId
      tokenType
      token
      role
      roleId
      profileData {
        firstName
        lastName
        email
        phone
      }
      userPermissions
      applicationSetting
    }
  }
`

export const REGISTER_NEW_DOCTOR_PROFILE = gql`
  mutation registerNewDoctorProfile($businessProfileInput: doctorBusinessInput!) {
    registerNewDoctorProfile(businessProfileInput: $businessProfileInput)
  }
`
